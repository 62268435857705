import React from 'react';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import PanelHeader from "./panelHeader";
import FormControlLabel from '@mui/material/FormControlLabel';
import {makeRandomKey} from "../../utils";

const CheckBoxPanel = ({objs, label, colors = [], orientation, maxLength = 0, onChange}) => {
    return (
        <Box sx={{display: 'fit-content', marginLeft: 3}}>
            <PanelHeader title={label} underline={false}/>
            <Stack direction={orientation} spacing={0} key={makeRandomKey()}>
                {Object.keys(objs).map((key, index) => {
                    const color = (colors[index] === undefined ? '' : colors[index])
                    return <FormControlLabel style={{display: 'fit-content'}}
                                             className={color}
                                             key={index}
                                             control={<Checkbox sx={{'& .MuiSvgIcon-root': {fontSize: 15}}}
                                                                value={key}
                                                                onChange={onChange}
                                                                checked={objs[key]}/>}
                                             label={(maxLength !== 0 ? key.substring(0, maxLength) : key)}
                    />

                })}
            </Stack>
        </ Box>
    )
        ;
}

export default CheckBoxPanel