import React, {useContext, useEffect, useState} from 'react';
import {TableVirtuoso} from 'react-virtuoso'
import {userContext} from "../../stores/users/userContext";
import {messageContext} from "../../stores/messages/messageContext";
import {getMyCreationsUrl} from '../constants'
import {observer} from "mobx-react-lite";
import {myCreationHeader} from "../header/headerConstants";
import {domainNoSlash} from "../constants";
import Box from '@mui/material/Box';
import Header from "../header/header";
import TextSubTitle from "../subtitles/subtitles";
import DownloadButton from "../download/downloadButton";
import Loading from "../loading/loading";
import Title from "../title/title";
import './mycreations.css'


const MyCreations = () => {
    const userStore = useContext(userContext)
    const messageStore = useContext(messageContext)
    const [myCreations, setMyCreations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [header, setHeader] = useState(myCreationHeader)

    const fetchData = async () => {
        try {
            setLoading(true)
            const response = await fetch(`${getMyCreationsUrl}?token=${userStore.getToken()}`)
            const data = await response.json()
            setMyCreations(data)
            if (data.length === 0) {
                messageStore.setMessage("You haven't downloaded or uploaded any videos yet, but feel free to do so using options menu.", 'info', 10000)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            messageStore.setMessage(error)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    if (loading) return <Loading/>


    const itemContent = (item, data) => {
        return (
            header.map((obj, index) => {
                if (obj.visible) {
                    if (obj.type === 'video') {
                        if (data['ext'] === 'mp4')
                            return (
                                <td key={index} className={`${obj.field} ${obj.type}`}>
                                    <video playsInline width="320" height="240" src={`${domainNoSlash}${data['video']}`} controls/>
                                </td>
                            )
                        if (data['ext'] === 'mp3')
                            return (
                                <td key={index} className={`${obj.field} ${obj.type}`}>
                                    <audio controls>
                                        <source src={`${domainNoSlash}${data['video']}`} type="audio/mpeg"/>
                                    </audio>
                                </td>
                            )
                        if (data['ext'] === 'gif')
                            return (
                                <td key={index} className={`${obj.field} ${obj.type}`}>
                                    <DownloadButton url={`${domainNoSlash}${data['video']}`} name={data['file_name']}/>
                                    <img width="320" src={`${domainNoSlash}${data['video']}`} alt="gif creation"/>
                                </td>
                            )
                        if (data['ext'] === 'srt')
                            return (
                                <td key={index} className={`${obj.field} ${obj.type}`}>
                                    <TextSubTitle text={data[obj.field]}/>
                                </td>
                            )

                        if (data['ext'] === 'vtt')
                            return (
                                <td key={index} className={`${obj.field} ${obj.type}`}>
                                    <TextSubTitle text={data[obj.field]}/>
                                </td>
                            )
                    }
                    if (obj.type === 'date')
                        return (
                            <td key={index} className={`${obj.field} ${obj.type}`}>
                                {data[obj.field]}
                            </td>
                        )
                    if (obj.type === 'string')
                        return (
                            <td key={index} className={`${obj.field} ${obj.type}`}>
                                <Title title={data[obj.field]} maxWidth={obj.width}/>
                            </td>
                        )
                }
                return null
            })
        )
    }


    return (
        <Box style={{width: '100%', margin: 5, paddingTop: 65}}>
            <TableVirtuoso
                style={{height: '100vh', width: '98hv', margin: '0 auto'}}
                fixedHeaderContent={() => <Header header={header} onClick={() => {
                }}/>}
                data={myCreations}
                itemContent={itemContent}
            />
        </Box>
    )
}

export default observer(MyCreations);