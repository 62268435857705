import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import PanelHeader from "./panelHeader";


const SliderNumeric = ({textLen, label, color, callback, reset}) => {
    const [minMax, _] = useState(textLen);
    const [values, setValues] = useState(textLen);

    const onChange = (event) => {
        setValues(event.target.value);
    }
    const onChangeCommitted = (event) => {
        callback(values)
    }
    // reset slider when reset is changed on filterReset
    useEffect(() => {
        setValues(textLen)
    }, [reset])

    return <Box sx={{display: 'fit-content', width: 120}}>
        <PanelHeader title={`${label} from: ${values[0]}  to: ${values[1]}`} underline={false}/>
        <Slider
            value={values}
            size="small"
            valueLabelDisplay="auto"
            marks={false}
            step={1}
            min={minMax[0]}
            max={minMax[1]}
            onChange={onChange}
            color={color}
            onChangeCommitted={onChangeCommitted}
        />
    </Box>
}

export default SliderNumeric
