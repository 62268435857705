import React, {useContext, useState} from 'react';
import {userContext} from "../../stores/users/userContext";
import Box from '@mui/material/Box';
import {useForm} from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import getCookies from "../CSRF/getCookies";
import {uploadUrl} from "../constants";
import Avatar from "@mui/material/Avatar";
import UploadIcon from '@mui/icons-material/Upload';
import Typography from "@mui/material/Typography";


const UpVideos = () => {
    const userStore = useContext(userContext)
    const [csrfToken, setCsrfToken] = useState(getCookies('csrftoken'))
    const {register, handleSubmit, formState: {errors}} = useForm()
    const [upload, setUpload] = useState(true)
    const [error, setError] = useState(null)
    const [message, setMessage] = useState(null)
    const [upError, setUpError] = useState(null)
    const [title, setTitle] = useState('')
    const [file, setFile] = useState('')

    const onSubmit = async (data) => {
        handleUploadState()
        const formData = new FormData();
        formData.append('csrfmiddlewaretoken', csrfToken)
        formData.append('token', userStore.getToken())
        formData.append('upload', file)
        formData.append('title', data.title)

        await fetch(uploadUrl, {
            method: 'POST',
            // mode: 'cors',
            body: formData,
            // headers: {
            //     'X-Csrftoken': csrfToken,
            //     'content-type': 'multipart/form-data'
            // },
        }).then(response => {
            debugger
            if (response.status === 200) {
                setMessage(`An email will be sent to ${userStore.getUserEmail()} when the video is ready for use.`)

            } else {
                throw new Error(response.statusText)
            }
        }).catch(error => {
            setUpError(error.message)
            handleUploadState()
        })
    }

    const resetState = () => {
        setUpload(true)
        setError(null)
        setMessage(null)
        setTitle('')
        setFile('')
        handleUploadState()
    }

    const handleUploadState = () => {
        console.log(title, file)
        console.log(title === '' || file === '')
        setUpload(title === '' || file === '')
    }
    const onErrorDismiss = () => {
        resetState()

    }

    const onMessageDismiss = () => {
        resetState()
        debugger
        window.location.reload()
    }

    const onChangeUpload = (event) => {
        setFile(event.target.files[0])
        handleUploadState()
    }

    const onChangeTitle = (event) => {
        setTitle(event.target.value)
        handleUploadState()
    }
    return (
        <Box sx={{marginTop: 10, maxWidth: 500, marginLeft: 'auto', marginRight: 'auto'}}>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <Avatar sx={{m: 1}}>
                    <UploadIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Set a title and upload a video
                </Typography>
            </Box>
            <Box sx={{mt: 3}}>
                <form onSubmit={handleSubmit(onSubmit)} encType="mltipart/form-data">
                    <input hidden {...register('csrfmiddlewaretoken')} value={csrfToken}/>
                    <input hidden {...register('token')} value={userStore.getToken()}/>

                    <TextField {...register('upload')}
                               fullWidth
                               id="upload"
                               label=""
                               defaultValue=""
                               name="upload"
                               type="file"
                               accept="video/*"
                               mode="onChange"
                               onChange={onChangeUpload}
                               sx={{mt: 3, mb: 2}}
                    />
                    <TextField {...register('title')}
                               fullWidth
                               id="title"
                               label="title"
                               name="title"
                               type="text"
                               mode="onChange"
                               defaultValue=""
                               onChange={onChangeTitle}
                               required
                    />
                    <Button type="submit"
                            disabled={upload}
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}>
                        Upload video
                    </Button>

                </form>
                {message &&
                    <Typography
                        fullWidth
                        color="success"
                        sx={{mt: 3, mb: 2, width: 'inherit'}}>
                        {message}
                    </Typography>}
                {message &&
                    <Button
                        fullWidth
                        color="success"
                        variant="contained"
                        sx={{mt: 3, mb: 2, width: '100%'}}
                        onClick={() => {
                            onMessageDismiss()
                        }}>
                        Click to dismiss
                    </Button>}
                {upError &&
                    <Button
                        fullWidth
                        color="error"
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                        onClick={onErrorDismiss}>
                        {error}! Click to dismiss
                    </Button>}

            </Box>
        </Box>
    )
}

export default UpVideos