import React, {useState, useContext} from 'react';
import Container from '@mui/material/Container';
import {useNavigate} from "react-router-dom";
import {SignUpForm} from './signup_form';
import {GetPass} from "./getPass";
import {GetEmail} from "./getEmailForm";
import {authUrl} from '../constants';
import {userContext} from '../../stores/users/userContext'
import {setLocalStorage} from '../../utils';
import setCookie from "../CSRF/setCookie"
import {toJS} from 'mobx'


export const SignUpLogin = () => {
    const userStore = useContext(userContext)
    const navigate = useNavigate();
    const [phase, setPhase] = useState(userStore.getPhase())
    const [error, setError] = useState(null)
    const [email, setEmail] = useState('')

    const onError = (error) => {
        setPhase('get_email')
        setError(null)
    }
    const gotToGetEmail = () => userStore.setPhase('get_email')
    const gotToLogin = () => userStore.setPhase('login')
    const gotToSignUp = () => userStore.setPhase('signup')
    const handleSubmit = async (data, csrfToken) => {
        // convert data to x-www-form-urlencoded
        const urlEncodedData = new URLSearchParams();

        setEmail(data.email)

        for (const pair of Object.entries(data)) {
            urlEncodedData.append(pair[0], pair[1])
        }
        const url = authUrl[phase]
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: urlEncodedData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRFToken': csrfToken,
            }
        })
        const result = await response.json()

        if (response.status === 200 && phase === 'get_email') {
            userStore.setPhase('get_pass')
            setPhase('get_pass')
        }
        if (response.status === 200 && phase === 'get_pass') {
            userStore.setPhase('logged')
            userStore.setToken(result.token)
            userStore.setUser(toJS(result.user))
            setPhase('logged')
            setLocalStorage('token', result.token)
            setCookie('token', result.token)
            navigate('/downloaded/')
        }
        if (response.status === 422) {
            setError('Invalid email or pass code')

        } else {
            if (response.status === 200) {
                // user created
                userStore.setPhase('login')
            }
        }

    };
    if (phase === 'signup') {
        return (
            <Container component="main" maxWidth="xs">
                <SignUpForm submit={handleSubmit} gotToLogin={gotToGetEmail}/>
            </Container>

        );
    }

    if (phase === 'get_pass') {
        return (
            <Container component="main" maxWidth="xs">
                <GetPass submit={handleSubmit}
                         email={email}
                         gotToSignUp={gotToSignUp}
                         error={error}
                         onError={onError}/>
            </Container>

        );
    }

    if (phase === 'get_email') {
        return (
            <Container component="main" maxWidth="xs">
                <GetEmail submit={handleSubmit} gotToSignUp={gotToSignUp}/>
            </Container>

        );
    }
}

