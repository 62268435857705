import {makeAutoObservable, observable} from "mobx"
import getCookie from "../../components/CSRF/getCookies"
import removeCookie from "../../components/CSRF/removeCookie"
import {getLocalStorage} from "../../utils"
import {getUserUrl, logoutUrl} from "../../components/constants"
import {toJS} from "mobx"

class UserStore {

    user = {}
    phase = 'get_email' // get_email, get_code, login
    token = null
    transcript = {'downloadId': null, 'title': '', 'transcriptId': null}

    // possible move this to a store for the subtitles
    previousWord = -1
    sentenceStart = -1
    intervalId = null
    timeOutId = null
    video = null
    item = null
    data = null

    startTime = 0
    //
    duration = 0
    totalDuration = 0
    constructor() {
        makeAutoObservable(this, {
            user: observable,
            phase: observable,
            token: observable,
            transcript: observable,
            previousWord: observable,
            sentenceStart: observable,
            intervalId: observable,
            timeOutId: observable,
            video: observable,
            startTime: observable,
            duration: observable,
            totalDuration: observable,
        })
        this.token = getLocalStorage('token')
        if (this.token !== null) this.getUserInfo()
    }

    getUserInfo = async () => {
        const result = await fetch(`${getUserUrl}${this.token}/`)
        const data = await result.json()
        if (result.status === 200) {
            this.setUser(data.user)
        } else {
            this.setToken(null)
            localStorage.removeItem('token')
            // removeCookie('token')
        }
    }

    logoutBackEnd = async () => {
        const response = await fetch(`${logoutUrl}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken'),
            },
            body: JSON.stringify({
                token: this.token,
            })
        })
        const data = await response.json()

        if (response.status === 200) {
            this.setUser({})
            this.setPhase('get_email')
            this.setToken(null)
            localStorage.removeItem('token')
            // removeCookie('token')
        }
    }

    setUser = (user) => this.user = user
    getUser = () => this.user

    getUserId = () => this.user.uuid
    getUserEmail = () => {
        return this.user.email
    }
    setUserTranscript = (downloadId, title, transcriptId) =>
        this.transcript = {
            'downloadId': downloadId,
            'title': title,
            'transcriptId': transcriptId
        }

    getUserDownloadId = () => this.transcript['downloadId']
    getUserTranscriptId = () => {
        return this.transcript['transcriptId']
    }
    getUserTranscriptTitle = () => this.transcript['title']
    isUserAuth = () => this.token !== null
    setToken = (token) => this.token = token
    getToken = () => this.token
    setPhase = (phase) => this.phase = phase
    getPhase = () => this.phase

    // refactor this to a store for the subtitles
    setPreviousWord = (previousWord) => this.previousWord = previousWord
    getPreviousWord = () => this.previousWord
    setSentenceStart = (sentenceStart) => this.sentenceStart = sentenceStart
    getSentenceStart = () => this.sentenceStart
    getSentenceEnd = () => this.data.end_in
    setIntervalId = (intervalId) => this.intervalId = intervalId
    getIntervalId = () => this.intervalId
    setTimeOutId = (timeOutId) => this.timeOutId = timeOutId
    getTimeOutId = () => this.timeOutId
    setVideo = (data, video, item) => {
        this.data = data
        this.video = video
        this.item = item
        this.sentenceStart = data.start_in
    }
    getVideo = () => this.video
    getVideoDurationMs = () => (this.data === null ? null : this.data.duration_ms)

    setTotalDuration = (duration) => this.totalDuration = duration
    getTotalDuration =() => this.totalDuration
    getItem = () => this.item
    getVideoData = () => this.data
    getStartIn = () => this.data.start_in
    getEndIn = () => this.data.end_in
    resetVideo = () => {
        this.data = null
        this.video = null
        this.item = null
        this.previousWord = -1
        this.sentenceStart = -1
        this.intervalId = null
    }

    setStartTime = (time) => this.startTime = time
    getStartTime = () => this.startTime

    setDuration = (duration) => this.duration = duration
    getDuration = () => this.duration

}

const userStore = () => new UserStore()

export default userStore
