import {makeAutoObservable, observable, runInAction, flow, toJS} from "mobx"
import {
    getUserVideosUrl,
    getSentencesUrl
} from "../../components/constants";

class DataStore {

    userDownloaded = []
    oldTranscriptsId = 1000
    transcriptId = -1
    title = ''
    sentences = []
    speakers = []
    length = [0,0]
    sec =[0,0]
    SentencesState = []
    myCreations = []
    loading = false
    error = null

    constructor() {
        makeAutoObservable(this, {
            userDownloaded: observable,
            oldTranscriptsId: observable,
            transcriptId: observable,
            title: observable,
            sentences: observable,
            length: observable,
            sec: observable,
            speakers: observable,
            myCreations: observable,
            loading: observable,
            error: observable,
            loadUserDownloaded: flow,
        })
    }

    setUserDownloaded = (userDownloaded) => this.userDownloaded = userDownloaded

    getUserDownloaded = () => this.userDownloaded


    setMyCreations = (myCreations) => this.myCreations = myCreations

    getMyCreations = () => this.myCreations

    loadUserDownloaded = () => {
        this.loading = true
        fetch(getUserVideosUrl)
            .then(res => res.json())
            .then(
                (result) => {
                    runInAction(() => {
                        this.userDownloaded = result
                        this.loading = false
                    })
                },
                (error) => {
                    runInAction(() => {
                        this.error = error
                        this.loading = false
                    })
                }
            )
    }

    // loadSentences = () => {
    //     this.loading = true
    //
    //     fetch(`${getSentencesUrl}${this.getTranscriptId()}/`).then(res => res.json())
    //         .then(
    //             (result) => {
    //                 runInAction(() => {
    //                     this.setSentences(result)
    //                     this.loading = false
    //                 })
    //             },
    //             (error) => {
    //                 runInAction(() => {
    //                     this.error = error
    //                     this.loading = false
    //                 })
    //             }
    //         )
    // }




}

const dataStore = () => new DataStore()

export default dataStore
