import React, {useContext} from "react"
import {userContext} from '../../stores/users/userContext';
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";


const Logout = () => {
    const userStore = React.useContext(userContext);
    const navigate = useNavigate();
    userStore.logoutBackEnd();
    navigate('/')

    return null
}

export default observer(Logout)