import React, {useContext} from 'react';
import {userContext} from "../../stores/users/userContext";
import {observer} from 'mobx-react-lite'

const Clock = () => {
    const userStore = useContext(userContext)
    return (
        <div style={{marginLeft: 35}}>
            <p style={{
                margin: 0,
                fontSize: 12
            }}>{userStore.getTotalDuration()}</p>
            <hr style={{margin: 0, border: 0, borderBottom: "1px solid gray"}}/>
            <p style={{
                margin: 0,
                fontSize: 12
            }}>{userStore.getDuration()}</p></div>
    )
}

export default observer(Clock)