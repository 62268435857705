export const makeRandomKey = () => `k-${Math.random() * 10000000000}`

export const sortNumbersKeepSpaces = (a, b) => {
    // expects an array number or spaces
    const aIsNumber = !isNaN(a);
    const bIsNumber = !isNaN(b);

    if (aIsNumber && !bIsNumber) {
        return 1; // Keep 'a' before 'b'
    } else if (!aIsNumber && bIsNumber) {
        return -1; // Move 'a' after 'b'
    }
    return 0; // Keep original order
};

// write to local storage
export const setLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
}


// read from local storage
export const getLocalStorage = (key) => {
    const value = localStorage.getItem(key)
    if (value) {
        return JSON.parse(value)
    }
    return null
}

export const escapeString =(text) => text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
