import React,{createContext} from 'react';
import {useLocalObservable} from 'mobx-react-lite';
import userStore from './userStore';

export const userContext = createContext();

const UserProvider = ({children}) => {
    const data = useLocalObservable(userStore);
    return (
        <userContext.Provider value={data}>
            {children}
        </userContext.Provider>
    )
}

export default UserProvider