import React from 'react'
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';


export default function About() {

    return (
        <div style={{'margin': '15%'}}>
            <Typography variant="h4" component="p">
                About Emo Transcript
            </Typography>
            <hr/>
            <br/>
            <Typography variant="h6" component="p" style={{'textAlign': 'justify'}}>
                Emo Transcript is a transcription tool meticulously crafted to assist content creators,
                offering a detailed, interactive, and easily navigable transcript of audio or video content.
                Distinct from other platforms that present transcripts in a book-like format,
                Emo Transcript showcases them line-by-line or sentence-by-sentence, which highlight
                in synchronization as you click play.
                Each sentence is accompanied by a downloadable thumbnail,
                generated for immediate visual reference.
                Merging Artificial Intelligence with Emotional Intelligence,
                users can filter all sentences in the transcript by various parameters,
                such as speaker, emotional sentiment, highlights, profanity,&nbsp;
                <Tooltip title="Not safe for work"><b>NSFW</b></Tooltip>&nbsp;content, and more.
                Moreover, Emo Transcript empowers users to download not only individual sentences but also to compile
                multiple sentences from across the transcript into a single video. The Emo Index even allows users to
                download any word from the transcript, providing a comprehensive, user-friendly transcription
                experience.
            </Typography>
            <br/>
            <hr/>
        </div>
    )
}

