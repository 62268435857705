import React, {useState, useContext} from 'react';
import AppBar from '@mui/material/AppBar';
import {useNavigate} from "react-router-dom";
import {observer} from 'mobx-react-lite'
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import {Link} from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import {userContext} from "../../stores/users/userContext";
import EmoName from '../emo/emoName';
import EmoMenu from "../menus/emoMenu";


const drawerWidth = 240;


const DrawerAppBar = ({changeMode, mode}: any) => {
    const navigate = useNavigate()
    const [mobileOpen, setMobileOpen] = useState(false);
    const user = useContext(userContext)


    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const handleClick = (e: any) => {
        e.preventDefault()
        navigate('/')
    }

    const drawer = (
        <Box sx={{textAlign: 'center'}}>
            <Typography variant="h6" sx={{my: 2}}>
                <Link to='/'>
                    EMO Transcripts
                </Link>
                <IconButton color="inherit" onClick={changeMode}>
                    {mode === 'light' ? <Brightness7Icon/> : <Brightness4Icon/>}
                </IconButton>
            </Typography>
            <Divider/>
            <List>
                {/*{navItems.map((item, i) => (*/}
                {/*    <Link to={`${navLink[i]}`} onClick={handleClick}>*/}
                {/*        <ListItem key={item} disablePadding>*/}
                {/*            <ListItemButton sx={{textAlign: 'center'}}>*/}
                {/*                <ListItemText primary={item}/>*/}
                {/*            </ListItemButton>*/}
                {/*        </ListItem>*/}
                {/*    </Link>*/}
                {/*))}*/}
            </List>
        </Box>
    );


    return (
        <Box sx={{display: 'flex'}}>
            <AppBar component="nav">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{mr: 2, display: {sm: 'none'}}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <EmoName name='Emo Transcripts'/>
                    <EmoMenu/>
                    <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                        <IconButton color="inherit" onClick={changeMode}>
                            {mode === 'light' ? <Brightness7Icon/> : <Brightness4Icon/>}
                        </IconButton>
                    </Box>

                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {xs: 'block', sm: 'none'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </Box>
    );
}

export default observer(DrawerAppBar)