import React, {useState} from "react";
import Box from "@mui/material/Box";
import {IconButton} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';


const style = {
    overflow: "auto",
    width: 320,
    height: 200,
    color: 'inherit',
    backgroundColor: 'inherit',
    border: '1px solid grey',
    resize: 'none',
    outline: 'none',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit'
}

const buttonStyle = {
    position: 'absolute',
    top: 150,
    left: 280,
    transform: 'rotate(180deg)',
    backgroundColor: 'inherit',
    color: 'orange',
    borderRadius: 0,
}
const TextSubTitle = ({text}) => {
    const [feedback, setFeedback] = useState('Copy to clipboard')

    const feedBackTimeout = () => {
        setTimeout(() => {
            setFeedback('Copy to clipboard')
        }, 1000)
    }
    const onCopy = (text) => {
        setFeedback('Copied!')

        navigator.clipboard.writeText(text).then(function () {
                // console.log('Async: Copying to clipboard was successful!');
            },
            function (err) {
                // console.error('Async: Could not copy text: ', err);
            });
        feedBackTimeout()
    }
    return (
        <Box position="relative" width={340}>
            <textarea style={style} value={text} readOnly/>
            <Tooltip title={feedback} placement="top">
                <IconButton style={buttonStyle}
                            onClick={() => {
                                onCopy(text)
                            }}
                >
                    <ContentCopyIcon style={{fontSize: 15}}/>
                </IconButton>
            </Tooltip>
        </Box>
    )
}


export default TextSubTitle;
