import React, {useContext} from 'react';
import Snackbar from '@mui/material/Snackbar';
import {observer} from 'mobx-react-lite';
import {messageContext} from './messageContext';
import Alert from '@mui/material/Alert';
import {useNavigate} from 'react-router-dom';

const Message = ({}) => {
    const messageStore = useContext(messageContext);
    const navigate = useNavigate();
    const {message, level, duration, link} = messageStore.getMessage()

    const handleClose = () => {
        messageStore.resetMessage()
        if(link !== '') {
            navigate(link)
        }
    }

    return (
        <Snackbar
            open={message !== ''}
            autoHideDuration={duration}
            onClose={handleClose}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
            <Alert elevation={6} variant="filled" onClose={handleClose} severity={level}>
                {message}
            </Alert>
        </Snackbar>
    )
}
export default observer(Message)
