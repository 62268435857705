import React from "react";
import {domain} from "../constants";
const HasVideo = ({data}) => {

    if (data['task_status'] === 'Pending' || data['task_status'] === 'In progress') {
        return (<div>No Video</div>)
    } else {
        return <video width="320" height="240" controls src={`${domain}${data['video']}`}/>
    }
}

export default HasVideo