import React, {useState} from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {observer} from 'mobx-react-lite';
import {useForm} from "react-hook-form";
import getCookies from "../CSRF/getCookies";

export const GetEmail = ({submit, gotToSignUp}) => {
    const [csrfToken, setCsrfToken] = useState(getCookies('csrftoken'))
    const {register, handleSubmit, formState: {errors}} = useForm()

    const onSubmit = (data) => submit(data, csrfToken)

    return (
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Avatar sx={{m: 1}}>
                <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
                Please enter your email address
            </Typography>
            <Box sx={{mt: 3}}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input hidden {...register('csrfmiddlewaretoken')} value={csrfToken}/>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField {...register('email')}
                                       required
                                       fullWidth
                                       id="email"
                                       label="Email Address"
                                       name="email"
                                       autoComplete="email"
                                       type="email"
                            />
                        </Grid>
                    </Grid>
                    <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
                        Get pass code
                    </Button>
                </form>
                <Button fullWidth variant="contained" sx={{mt: 3, mb: 2}} onClick={gotToSignUp}>
                    Don't have an account? Sign up!
                </Button>
            </Box>

        </Box>
    )
}

export default observer(GetEmail)