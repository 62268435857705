import React, {useState} from "react";
import Box from "@mui/material/Box";
import {IconButton} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Tooltip from '@mui/material/Tooltip';
import {saveAs} from 'file-saver';


const style = {
    overflow: "auto",
    width: 340,
    height: 200,
    color: 'inherit',
    backgroundColor: 'inherit',
    border: '1px solid grey',
    resize: 'none',
    outline: 'none',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit'
}

const buttonStyle = {
    position: 'absolute',
    top: 150,
    left: 280,
    backgroundColor: 'inherit',
    borderRadius: 0,
}

const DownloadButton = ({url, name}) => {

    const download = () => {
        saveAs(url.replace('//','/'),name)
    }

    return (
        <Box position="relative" width={340}>
            <Tooltip title={'Download'} placement="top">
                <IconButton style={buttonStyle}
                            onClick={() => download()}
                >
                    <FileDownloadIcon style={{fontSize: 18}}/>
                </IconButton>
            </Tooltip>
        </Box>
    )
}


export default DownloadButton;
