import React from "react";
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import FormatListNumberedSharpIcon from '@mui/icons-material/FormatListNumberedSharp';
import {useTheme} from "@mui/material/styles";
import {iconStyle} from "./headerConstants";
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';



const Header = ({header, onClick, Menu = null, checked = [false, false], handleCheck}) => {
    const theme = useTheme();
    const mode = theme.palette.mode;

    const SortArrow = ({obj, index}) =>
        <IconButton onClick={() => onClick(index, (header[index].isUp ? 'down' : 'up'))}>
            {(header[index].isUp ? <ArrowDropDownIcon color="action" sx={{fontSize: 15}}/> :
                <ArrowDropUpIcon color="action" sx={{fontSize: 15}}/>)}
        </IconButton>

    return (
        <tr style={{width: '100%', backgroundColor: mode === 'dark' ? 'black' : 'white'}}>
            {header.map((obj, index) => {
                if (obj.visible) {
                    if (obj.restore)
                        return (
                            <th key={index} style={{width: obj.width}}>
                                <IconButton onClick={() => onClick(index)} style={{paddingTop: 0}}>
                                    <FormatListNumberedSharpIcon sx={iconStyle}/>
                                </IconButton>
                            </th>
                        )
                    if (obj.type === 'checkbox')
                        return (
                            <th key={index} style={{width: obj.width}}>
                                <Checkbox sx={{'& .MuiSvgIcon-root': {fontSize: 15}}}
                                          checked={checked[0]}
                                          indeterminate={checked[1]}
                                          onChange={handleCheck}
                                />

                            </th>
                        )
                    return (
                        <th key={index} style={{width: obj.width}}>
                            <Stack direction="row" spacing={0} alignItems={"flex-start"}>
                                {obj.title}
                                {(obj.sort ? <SortArrow index={index}/> : null)}
                            </Stack>
                        </th>
                    )
                }
                return null

            })}
        </tr>
    )
}

export default Header;