import React, {useState} from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useForm} from "react-hook-form";

export const GetPass = ({submit, email, gotToSignUp, error, onError}) => {
    const [csrfToken, setCsrfToken] = useState('')
    const {register, handleSubmit, formState: {errors}} = useForm()
    const onSubmit = (data) => submit(data, csrfToken)

    return (
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Avatar sx={{m: 1,}}>
                <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
                Log in
            </Typography>
            <Typography component="p" variant="h6">
                Please check your email for the pass code.
            </Typography>
            <Box sx={{mt: 3}}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input hidden {...register('csrfmiddlewaretoken')} value={csrfToken}/>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                {...register('email')}
                                required
                                fullWidth
                                id="email"
                                label="Email this Address"
                                name="email"
                                value={email}
                                autoComplete="email"
                                type="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                {...register('code')}
                                required
                                fullWidth
                                id="code"
                                label="Pass code"
                                name="code"
                                type="text"
                            />
                        </Grid>
                    </Grid>

                    <Button type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}>

                        Log in
                    </Button>

                    <Button fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                            onClick={gotToSignUp}>

                        Don't have an account? Sign up
                    </Button>

                    {error &&
                        <Button
                            fullWidth
                            color="error"
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                            onClick={() => {
                                onError()
                            }}>
                            {error}! Click to dismiss
                        </Button>}
                </form>
            </Box>
        </Box>
    )
}