import {createTheme} from '@mui/material/styles';

const createCustomTheme = (mode: any) => {
    if (mode === 'light') {
        return createTheme({
            palette: {
                mode: mode,
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            fontSize: '1rem',
                            textTransform: 'none',
                        },
                    },
                },

            },
        });
    } else {
        return createTheme({
            palette: {
                mode: mode,
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            fontSize: '1rem',
                            textTransform: 'none',
                        },
                    },
                },
            }
        });
    }
}
export default createCustomTheme;