import {Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

const EmoName = ({name}) => {
    const navigate = useNavigate();

    const onClick = () => {
        navigate('/')
    }

    return (
        <Typography variant="h6"
                    component="div"
                    sx={{flexGrow: 1, display: {xs: 'none', sm: 'block', cursor: 'pointer'}}}
                    onClick={onClick}>
            {name}
        </Typography>
    )
}

export default EmoName