import React, {createContext} from 'react';
import {useLocalObservable} from 'mobx-react-lite';
import dataStore from './dataStore';

export const dataContext = createContext();

const DataProvider = ({children}) => {
    const data = useLocalObservable(dataStore);
    return (
        <dataContext.Provider value={data}>
            {children}
        </dataContext.Provider>
    )
}

export default DataProvider;