import React from 'react';
import {useNavigate} from "react-router-dom";
import Button from '@mui/material/Button';
import {userContext} from "../../stores/users/userContext";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import {menuItemsNotLogged, menuItemsLogged} from "./constants";
import {useContext} from "react";

const ITEM_HEIGHT = 200;
const ITEM = 0
const LINK = 1

const EmoMenu = () => {
    const navigate = useNavigate();
    const userStore = useContext(userContext)
    const transcriptId = (userStore.getUserTranscriptId() ===undefined ? null : userStore.getUserTranscriptId())
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const menuItems = (userStore.isUserAuth() ? menuItemsLogged : menuItemsNotLogged)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

    };
    const handleClose = (event, index) => {
        setAnchorEl(null);
    };
    const menuItemClick = (index) => {
        setAnchorEl(null);
        navigate(menuItems[index][LINK])
    }
    return (
        <div>
            <Button
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                color="inherit"
                onClick={handleClick}
            >
                Options
            </Button>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '100ch',
                    },
                }}
            >
                {menuItems.map((item, index) => (
                        (item[ITEM] === '-') ?
                            <Divider key={index}/> :
                            <MenuItem key={index}
                                      disabled={item[ITEM] === 'Transcript' && transcriptId === null}
                                      onClick={() => menuItemClick(index)}>
                                {item[ITEM]}
                            </MenuItem>
                    )
                )}
            </Menu>
        </div>
    );
}

export default EmoMenu;