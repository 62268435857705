import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import Clock from "../clock/clock";


export const iconStyle = {
    fontSize: 15,
    marginTop: 0.5,
}


export const sentencesHeader = [

    {
        visible: true,
        field: '',
        title: '',
        sort: false,
        filter: false,
        type: 'checkbox',
        isUp: false,
        width: 20,
        value: false,
        restore: false
    },
    {
        visible: true,
        field: 'order',
        title: '#',
        sort: false,
        filter: false,
        type: 'number',
        isUp: false,
        width: 20,
        value: false,
        restore: true

    },
    {
        visible: true,
        field: 'speaker',
        title: <RecordVoiceOverIcon sx={iconStyle}/>,
        sort: true,
        filter: true,
        type: 'icon',
        isUp: false,
        width: 20,
        value: false,
        restore: false

    },
    {
        visible: true,
        field: 'sentiment',
        title: 'Emo',
        sort: true,
        filter: true,
        type: 'string',
        isUp: false,
        width: 35,
        value: true,
        restore: false
    },

    {
        visible: true,
        field: 'play',
        title: 'Play',
        sort: false,
        filter: true,
        type: 'video',
        isUp: false,
        width: 50,
        value: false,
        restore: false
    },


    {
        visible: true,
        field: 'sentence',
        title: 'Transcript',
        sort: true,
        filter: true,
        type: 'string',
        isUp: false,
        width: 150,
        value: false,
        restore: false
    },
    {
        visible: true,
        field: 'sec',
        title: 'Sec',
        sort: true,
        filter: true,
        type: 'number',
        isUp: false,
        width: 35,
        value: false,
        restore: false
    },
    {
        visible: true,
        field: 'length',
        title: 'Len',
        sort: true,
        filter: true,
        type: 'number',
        isUp: false,
        width: 35,
        value: false,
        restore: false
    },
    {
        visible: true,
        field: 'question',
        title: '?',
        sort: true,
        filter: true,
        type: 'boolean',
        isUp: false,
        width: 30,
        value: false,
        restore: false
    },
    {
        visible: true,
        field: 'verbs',
        title: 'Verbs',
        sort: true,
        filter: true,
        type: 'string',
        isUp: false,
        width: 60,
        value: false,
        restore: false
    },
    {
        visible: true,
        field: 'adjectives',
        title: 'Adj',
        sort: true,
        filter: true,
        type: 'string',
        isUp: false,
        width: 60,
        value: false,
        restore: false
    },
    {
        visible: true,
        field: 'nouns',
        title: 'Nouns',
        sort: true,
        filter: true,
        type: 'string',
        isUp: false,
        width: 65,
        value: false,
        restore: false
    },
    {
        visible: false,
        field: 'start',
        title: 'Start',
        sort: true,
        filter: true,
        type: 'time',
        isUp: false,
        width: 100,
        value: false,
        restore: false
    },
    {
        visible: false,
        field: 'stop',
        title: 'Stop',
        sort: true,
        filter: true,
        type: 'time',
        isUp: false,
        width: 100,
        value: false,
        restore: false
    },


    {
        visible: true,
        field: 'duration',
        title: <Clock/>,
        sort: false,
        filter: false,
        type: 'icon',
        isUp: false,
        width:40,
        value: false,
        restore: false
    },
]

export const downloadedHeader = [
    {
        visible: true,
        field: '',
        title: '',
        sort: false,
        filter: false,
        type: 'none',
        isUp: false,
        width: '5%',
        value: false,
        restore: false
    },
    {
        visible: true,
        field: 'title',
        title: 'Title',
        sort: true,
        filter: true,
        type: 'string',
        isUp: false,
        width: '25%',
        value: false,
        restore: false
    },
    {
        visible: true,
        field: 'play',
        title: 'Play',
        sort: false,
        filter: false,
        type: 'video',
        isUp: false,
        width: '300',
        value: false,
        restore: false
    },
    {
        visible: true,
        field: 'status',
        title: 'Status',
        sort: true,
        filter: true,
        type: 'string',
        isUp: false,
        width: '10%',
        value: false,
        restore: false
    },
    {
        visible: true,
        field: 'task_status',
        title: 'Task',
        sort: true,
        filter: true,
        type: 'string',
        isUp: false,
        width: '10%',
        value: false,
        restore: false
    },
    {
        visible: true,
        field: 'downloaded',
        title: 'Downloaded',
        sort: true,
        filter: true,
        type: 'dateTime',
        isUp: false,
        width: '15',
        value: false,
        restore: false
    },

]

export const myCreationHeader = [

    {
        visible: true,
        field: 'title',
        title: 'Title',
        sort: true,
        filter: false,
        type: 'string',
        isUp: false,
        width: '400',
        value: false,
        restore: false
    },
     {
        visible: true,
        field: 'video',
        title: 'Play',
        sort: false,
        filter: false,
        type: 'video',
        isUp: false,
        width: '340',
        value: false,
        restore: false
    },
     {
        visible: true,
        field: 'type',
        title: 'type',
        sort: false,
        filter: false,
        type: 'string',
        isUp: false,
        width: '100',
        value: false,
        restore: false
    },
    {
        visible: true,
        field: 'created',
        title: 'Created',
        sort: true,
        filter: false,
        type: 'date',
        isUp: false,
        width: '100',
        value: false,
        restore: false
    },



]