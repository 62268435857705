import {makeAutoObservable, action, observable, runInAction, toJS} from "mobx"


class FilterStore {

    // filters
    sentiments = {'positive': false, 'negative': false, 'neutral': false}
    speakers = {}
    length = []
    sec = []
    search = ''
    subtitles = true
    spaces = false
    selectedSentences = []

    constructor() {
        makeAutoObservable(this, {
            sentiments: observable,
            length: observable,
            sec: observable,
            speakers: observable,
            search: observable,
            selectedSentences: observable,
            subtitles: observable,
            spaces: observable,
            resetFilters: action,
        })

    }

    setSentiments = (key) => this.sentiments = {...this.sentiments, [key]: !this.sentiments[key]}
    getSentiments = () => this.sentiments
    getSentimentsTrue = () => Object.keys(this.sentiments).filter(key => this.sentiments[key])
    getSentimentLabels = () => Object.keys(this.sentiments)
    getSentimentsIsEmpty = () => this.sentiments.length === 0
    setTextLen = (length) => this.length = length
    getTextLen = () => this.length
    getTextLenIsEmpty = () => this.length.length === 0
    setSecMaxMin = (secMaxMin) => this.secMaxMin = secMaxMin
    setSeconds = (sec) => this.sec = sec
    getSeconds = () => this.sec
    getSecondsIsEmpty = () => this.sec.length === 0
    setSpeakers = (speakers) => this.speakers = Object.fromEntries(speakers.map(speaker => [speaker, false]))
    getSpeakers = () => this.speakers
    setResetSpeakers = (speaker) => this.speakers = {...this.speakers, [speaker]: !this.speakers[speaker]}
    getSpeakersTrue = () => Object.keys(this.speakers).filter(key => this.speakers[key])
    getSpeakersIsEmpty = () => this.speakers.length === 0
    setSearch = (search) => this.search = search
    getSearch = () => this.search
    getSearchEscaped = () => this.search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    setSelectedSentences = (sentence) => this.selectedSentences.push(sentence)
    isSelectedSentence = (sentence) => this.selectedSentences.includes(sentence)
    getSelectedSentencesLength = () => this.selectedSentences.length
    hasSelectedSentences = () => ! this.selectedSentences.length <= 0
    howManySelectedSentences = () => this.selectedSentences.length
    setAllSelectedSentences = (sentences) => {
        this.selectedSentences = []
        sentences.forEach(sentence => this.selectedSentences.push(sentence['id']))
    }
    getSelectedSentences = () => this.selectedSentences
    resetSelectedSentences = () => this.selectedSentences = []
    removeSelectedSentence = (sentence) => {
        const index = this.selectedSentences.indexOf(sentence)
        if (index > -1) {
            this.selectedSentences.splice(index, 1)
        }
    }

    setSubtitlesOnOff = () => this.subtitles = !this.subtitles
    getSubtitlesOnOff = () => this.subtitles
    setSpacesOnOff = () => this.spaces = !this.spaces
    getSpacesOnOff = () => this.spaces


    resetFilters = (speakers, length, sec) => {
        runInAction(() => {
            this.sentiments = {'positive': false, 'negative': false, 'neutral': false}
            this.speakers = Object.fromEntries(speakers.map(speaker => [speaker, false]))
            this.length = length
            this.sec = sec
            this.subtitles = true
            this.spaces = false
            this.search = ''
            this.selectedSentences = []
        })

    }
}

const filterStore = () => new FilterStore()

export default filterStore
