import React, {useContext, useEffect, useState} from "react";
import {observer,} from 'mobx-react-lite'
import {toJS} from "mobx"
import Box from '@mui/material/Box';
import Loading from "../loading/loading";
import {messageContext} from "../../stores/messages/messageContext";
import {userContext} from "../../stores/users/userContext";
import {getUserVideosUrl} from "../constants";
import {downloadedHeader} from "../header/headerConstants";
import Radio from "@mui/material/Radio";
import {TableVirtuoso} from "react-virtuoso";
import HasVideo from "../grid/hasVideo";
import Header from "../header/header";
import './downloaded.css'

const Downloaded = () => {
    const userStore = useContext(userContext)
    const message = useContext(messageContext)
    const [userDownloaded, setUserDownloaded] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [header, setHeader] = useState(downloadedHeader)

    const radioChange = (event, item) => {
        userStore.setUserTranscript(
            userDownloaded[item]['id'],
            userDownloaded[item]['title'],
            userDownloaded[item]['transcript_id'])
    }

    useEffect(() => {
        setLoading(true)
        fetch(`${getUserVideosUrl}${userStore.getToken()}/`)
            .then(res => res.json())
            .then(
                (result) => {
                     setLoading(false);
                    if(result.length === 0) {
                        message.setMessage("You haven't downloaded or uploaded any videos yet, but feel free to do so using options menu.", 'info', 10000)

                    }else {
                        setUserDownloaded(result);
                        userStore.setUserTranscript(result[0]['transcript_id'], result[0]['title'], result['id']);
                    }
                },
                (error) => {
                    setError(error);
                    setLoading(false);
                }
            )
    }, [])

    // useEffect(() => {
    //     setUserDownloaded(store.getUserDownloaded())
    // }, [])

    const onSortClick = (index, direction) => {
        // for the time being sort only by one column
        const newHeader = header.map((item, i) => {
            if (i === index) {
                item.isUp = !item.isUp
                return item
            }
            item.isUp = false
            return item
        })
        setHeader(newHeader)
        userDownloaded.sort((a, b) => {
            if (direction === 'up') {
                return a[header[index].field] > b[header[index].field] ? 1 : -1
            } else {
                return a[header[index].field] < b[header[index].field] ? 1 : -1
            }
        })
    }

    const itemContentDownloaded = (item, data) => {

        return (
            <>
                <td>
                    <Radio
                        style={{padding: 10, fontSize: 15}}
                        value={data['transcript_id']}
                        checked={userStore.getUserTranscriptId() == data['transcript_id']}
                        onChange={(event) => {
                            radioChange(event, item, data['transcript_id'])
                        }}
                        name="radio-group"
                        disabled={data['transcript_id'] === ''}
                    />
                </td>
                <td>{data['title']}</td>
                <td>
                    <HasVideo data={data}/>
                </td>
                <td>{data['status']}</td>
                <td>{data['task_status']}</td>
                <td>{data['downloaded']}</td>

            </>
        )
    }

    if (loading) return <Loading/>;
    if (error) message.setMessage(`Error:${error}`, 'error')

    return (
        <Box sx={{background: 'background.paper', width: '100%', margin: '0 auto'}}>
            <h3>Downloaded {userStore.getUserDownloadId()}</h3>
            <TableVirtuoso
                useWindowScroll
                style={{height: '100%', width: '100%'}}
                data={userDownloaded}
                fixedHeaderContent={() => <Header header={header} onClick={onSortClick}/>}
                itemContent={itemContentDownloaded}
            />
        </Box>
    )
}

export default observer(Downloaded)