import React, {useContext} from 'react';
import {useNavigate} from "react-router-dom";
import {messageContext} from "../../stores/messages/messageContext";
import {userContext} from "../../stores/users/userContext";
import {filterContext} from "../../stores/filters/filterContext";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import getCookies from "../CSRF/getCookies";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {makeVideoUrl} from "../constants";
import {toJS} from "mobx"

const ITEM_HEIGHT = 200;
const ITEM = 0

const SentencesMenu = () => {
    const navigate = useNavigate();
    const message = useContext(messageContext)
    const userStore = useContext(userContext)
    const transcriptId = userStore.getUserTranscriptId()
    const downloadId = userStore.getUserDownloadId()
    const title = userStore.getUserTranscriptTitle()

    const filterStore = useContext(filterContext)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const menuItems = [
        ['Create a MP4 video', ''],
        ['Create a MP3 audio', ''],
        ['Create a GIF', ''],
        ['Create a SRT file', ''],
        ['Create a VTT file', ''],
    ]
    const KIND = ['mp4', 'mp3', 'gif', 'srt', 'vtt']
    const makeVideo = async (index) => {
        const data = {
            'kind': KIND[index],
            'list_of_ids': filterStore.getSelectedSentences(),
            'token': userStore.getToken(),
            'transcript_id': transcriptId,
            'download_id': downloadId,
            'title': title,
        }
        const urlEncodedData = new URLSearchParams();
        for (const pair of Object.entries(data)) {
            urlEncodedData.append(pair[0], pair[1])
        }
        const response = await fetch(makeVideoUrl, {
            method: 'POST',
            mode: 'cors',
            body: urlEncodedData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRFToken': getCookies('csrftoken'),
            }
        })
        const result = await response.json()

        if (response.status !== 200) {
            message.setMessage(result.message)
        }
    }


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, index) => {
        setAnchorEl(null);
    };
    const menuItemClick = (index) => {
        console.log('menuItemClick', filterStore.hasSelectedSentences())
        if (!filterStore.hasSelectedSentences()) {
            message.setMessage('Please select at least one sentence')
        }
        setAnchorEl(null);
        makeVideo(index)


    }
    return (
        <div>
            <Button
                style={{color: 'white'}}
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon/>}
            >
                Actions
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '100ch',
                    },
                }}
            >
                {menuItems.map((item, index) => (
                        (item[ITEM] === '-') ?
                            <Divider key={index}/> :
                            <MenuItem key={index} onClick={() => menuItemClick(index)}>
                                {item[ITEM]}
                            </MenuItem>
                    )
                )}
            </Menu>
        </div>
    );
}

export default SentencesMenu;