import React, {useEffect, useState} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {observer} from 'mobx-react-lite'
import CssBaseline from '@mui/material/CssBaseline';
import DrawerAppBar from "./components/appbar/appbar";
import {ThemeProvider} from '@mui/material/styles';
import {createTheme} from '@mui/material/styles';
import MessageProvider from "./stores/messages/messageContext";
import UserProvider from "./stores/users/userContext";
import DataProvider from "./stores/data/dataContext";
import FilterProvider from "./stores/filters/filterContext";
import Message from './stores/messages/Message';
import {CheckVersion} from "./stores/messages/checkversion";
import createCustomTheme from "./components/theme/createCustom";
import Router from "./components/router/router"
import './index.css'


const getOSModeOrUserMode = () => {

    const mode = localStorage.getItem('mode')
    if (mode) {
        return mode
    }
    return window.matchMedia("(prefers-color-scheme: dark)").matches ? 'dark' : 'light'
}

const App = () => {

    const [mode, setMode] = useState(getOSModeOrUserMode())
    const [theme, setTheme] = useState(createTheme({palette: {type: mode}}))

    const changeMode = () => {
        const newMode = mode === 'dark' ? 'light' : 'dark'
        setMode(newMode)
        localStorage.setItem('mode', newMode)
        return newMode
    }


    useEffect(() => {
        setTheme(createCustomTheme(mode))
    }, [mode])

    useEffect(() => {
            window
                .matchMedia("(prefers-color-scheme:dark)")
                .addEventListener("change", (event) => {
                    const newMode = event.matches ? 'dark' : 'light'
                    setMode(newMode)
                    localStorage.setItem('mode', newMode)
                })

            return () => window.matchMedia("(prefers-color-scheme:dark)").removeEventListener("change", (event) => {
            })
        },
        [])
    return (

        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <DataProvider>
                <MessageProvider>
                    <CheckVersion/>
                    <UserProvider>
                        <FilterProvider>
                            <BrowserRouter>
                                <Message/>
                                <DrawerAppBar changeMode={changeMode} mode={mode}/>
                                <Router/>
                            </BrowserRouter>
                        </FilterProvider>
                    </UserProvider>
                </MessageProvider>
            </DataProvider>
        </ThemeProvider>
    )
}
export default observer(App);
