import React from 'react'
import notFound from '../images/404.jpg'
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';


export default function Not_found_404() {
    return (
        <div style={{'marginTop':100, marginLeft:100}}>
            <Typography variant="h4" component="p" >
                <p>It's not the end of the word as we know it,</p>
                <p>just a page not found.</p>
                <p>Try this link instead:<Link to="/">Home</Link></p>
            </Typography>

            <img src={notFound} alt="404" width="300"/>
        </div>
    )
}