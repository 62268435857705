import React, {createContext} from 'react';
import {useLocalObservable} from 'mobx-react-lite';
import filterStore from './filterStore';

export const filterContext = createContext();

const DataProvider = ({children}) => {
    const data = useLocalObservable(filterStore);
    return (
        <filterContext.Provider value={data}>
            {children}
        </filterContext.Provider>
    )
}

export default DataProvider;