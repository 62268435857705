import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {getUserUrl} from '../constants';
axios.defaults.withCredentials = true;

export const GetUser = () => {
    const [user, setUser] = useState({});
    const fetchData = async () => {
        const response = await axios.get(getUserUrl, {'withCredentials': true });
        setUser(response.data);
    }
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div style={{top:100}}>
            <h1>User</h1>
            {user.email}
        </div>
    )
}