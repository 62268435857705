import React, {useContext, useState} from 'react';
import {userContext} from "../../stores/users/userContext";
import Box from '@mui/material/Box';
import {useForm} from "react-hook-form";
import Avatar from "@mui/material/Avatar";
import DownloadIcon from '@mui/icons-material/Download';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import getCookies from "../CSRF/getCookies";
import {downloadUrl} from "../constants";

const DnVideos = () => {
    const userStore = useContext(userContext)
    const [csrfToken, setCsrfToken] = useState(getCookies('csrftoken'))
    const {register, handleSubmit, formState: {errors}} = useForm()
    const [download, setDownload] = useState(true)
    const [error, setError] = useState(null)
    const [message, setMessage] = useState(null)
    const [upError, setUpError] = useState(null)
    const [url, setUrl] = useState('')

    const onSubmit = async (data) => {
        setDownload(true)
        // convert data to x-www-form-urlencoded
        const urlEncodedData = new URLSearchParams();
        for (const pair of Object.entries(data)) {
            urlEncodedData.append(pair[0], pair[1])
        }
        const response = await fetch(downloadUrl, {
            method: 'POST',
            mode: 'cors',
            body: urlEncodedData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRFToken': csrfToken,
            },
        }).then(response => {
            if (response.status === 200) {
                setMessage(`An email will be sent to ${userStore.getUserEmail()} when the video is ready for use.`)
                return response.json()
            } else {
                throw new Error(response.statusText)
            }
        }).catch(error => {
            setError(error.message)
            setDownload(false)
        })
    }

    const onErrorDismiss = () => {
        setUrl('')
        setError(null)
        setDownload(true)
    }

    const onMessageDismiss = () => {
        setMessage(null)
        setUrl('')
        setError(null)
        setDownload(true)
    }

    const onChangeDownload = (event) => {
        setUrl(event.target.value)
        setDownload(false)
    }

    return (
        <Box sx={{marginTop: 10, maxWidth: 500, marginLeft: 'auto', marginRight: 'auto'}}>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <Avatar sx={{m: 1}}>
                    <DownloadIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                   Enter a URL to download a video
                </Typography>
            </Box>
            <Box sx={{mt: 3}}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input hidden {...register('csrfmiddlewaretoken')} value={csrfToken}/>
                    <input hidden {...register('token')} value={userStore.getToken()}/>
                    <TextField {...register('url')}
                               fullWidth
                               id="url"
                               label="URL"
                               name="url"
                               type="url"
                               value={url}
                               onChange={onChangeDownload}
                    />
                    <Button type="submit"
                            disabled={download}
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}>
                        Download video
                    </Button>
                    {error &&
                        <Typography
                            fullWidth
                            color="success"
                            sx={{mt: 3, mb: 2, width: 'inherit'}}>
                            {error}.
                        </Typography>}
                    {error &&
                        <Button
                            fullWidth
                            color="error"
                            variant="contained"
                            sx={{mt: 3, mb: 2, width: '100%'}}
                            onClick={() => {
                                onErrorDismiss()
                            }}>
                            Click to dismiss
                        </Button>}
                    {message &&
                        <Typography
                            fullWidth
                            color="success"
                            sx={{mt: 3, mb: 2, width: 'inherit'}}>
                            {message}
                        </Typography>}
                    {message &&
                        <Button
                            fullWidth
                            color="success"
                            variant="contained"
                            sx={{mt: 3, mb: 2, width: '100%'}}
                            onClick={() => {
                                onMessageDismiss()
                            }}>
                            Click to dismiss
                        </Button>}
                </form>
                {upError &&
                    <Button
                        fullWidth
                        color="error"
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                        onClick={onErrorDismiss}>
                        {error}! Click to dismiss
                    </Button>}
            </Box>
        </Box>

    )
}

export default DnVideos