import {useContext} from "react";
import {messageContext} from './messageContext'
import version from "../../version.json";

export const CheckVersion = () => {
    const message = useContext(messageContext)
    const localVersion = localStorage.getItem('version')

    if (localVersion !== version.version) {
        localStorage.setItem('version', version.version)
        message.setMessage('Please refresh the page a new version of the app has been released', 'info', 100000)
    }
    return null
}