import React from "react";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import PanelHeader from "./panelHeader";

const Results = ({header, number}) => {
    return (
        <Box sx={{display: 'fit-content', marginLeft: 3}}>
             <PanelHeader title={"Rows"} underline={false}/>
            <Stack direction={'row'} spacing={0}>
                <span style={{margin:7}}>{number}</span>
            </Stack>
        </Box>
    )
}

export default Results;
