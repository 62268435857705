import React, {useState, useEffect, useContext} from 'react';
import {observer} from 'mobx-react-lite'
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import {TableVirtuoso} from 'react-virtuoso'
import AppBar from '@mui/material/AppBar';
import parse from 'html-react-parser';
import Grid from '@mui/material/Unstable_Grid2'
import {getSentencesUrl, domainNoSlash} from '../constants';
import Header from "../header/header";
import {userContext} from "../../stores/users/userContext";
import {messageContext} from "../../stores/messages/messageContext";
import {filterContext} from "../../stores/filters/filterContext";
import CheckIcon from '@mui/icons-material/Check';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import IconButton from '@mui/material/IconButton';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import SubtitlesOffIcon from '@mui/icons-material/SubtitlesOff';
import CheckboxPanel from "./checkBoxPanel";
import SliderNumeric from "./sliderNumeric";
import SearchField from "./search";
import Loading from "../loading/loading";
import Stack from '@mui/material/Stack';
import {sentencesHeader} from "../header/headerConstants";
import SentencesMenu from "../menus/sentencesMenu";
import Result from "./results";
import './createVideo.css'
import {toJS} from "mobx"
import {Typography} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import {useTheme} from "@mui/material/styles";
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';
import CommentIcon from '@mui/icons-material/Comment';


const PATH = 0
const FILENAME = 1
const INDEX = 2
const DATA = 3
const TITLE = 4
const TITLE_SLUG = 5
const VIDEO_DURATION = 6
const INTERVAL = 75
const INTERVAL_MS = INTERVAL / 1000

const iconStyle = {
    fontSize: 18,
}


const CreateVideo = () => {
    const maxLines = 99999
    const userStore = useContext(userContext)
    const filterStore = useContext(filterContext)
    const transcriptId = userStore.getUserTranscriptId()
    const [loading, setLoading] = useState(true);
    const [originalRows, setOriginalRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [header, setHeader] = useState(sentencesHeader)
    const [reset, setReset] = useState(null)
    const [order, setOrder] = useState(1)
    const [checked, setChecked] = useState([false, false])
    const [videoFile, setVideoFile] = useState('')
    const [videoDuration, setVideoDuration] = useState(0)
    const [thumbnailFile, setThumbnailFile] = useState('')
    const sentClasses = {'0': 'positive', '1': 'negative', '2': 'neutral'}

    // need to be reactive to theme changes todo
    const theme = useTheme();
    const mode = theme.palette.mode;
    const subtitleColor = mode === 'dark' ? 'white' : 'black'
    const subtitleColorHighlight = mode === 'dark' ? 'orange' : 'red'

    const durationFormat = (duration) => new Date(duration).toISOString().slice(11, -5);

    const calcDuration = (data) => {
        const ids = filterStore.getSelectedSentences()
        if (ids.length > 0) {
            return durationFormat(data.filter(obj => ids.includes(obj['id'])).reduce((a, b) => a + b['duration_ms'], 0))
        }
        return durationFormat(data.reduce((a, b) => a + b['duration_ms'], 0))
    }

    const reGex = (searchString) => [
        new RegExp(`\\b${searchString}\\b`, 'gi'),
        new RegExp(`\\b${searchString}`, 'i'),
        new RegExp(`${searchString}\\b`, 'i')
    ]

    const [regex, regex1, regex2] = reGex(filterStore.getSearchEscaped())

    const filterAll = () => {
        if (originalRows.length === 0) return
        shouldStopVideo()
        let filterRows = [...originalRows]
        filterRows = filterSentiment(filterRows)
        filterRows = filterSpeakers(filterRows)
        filterRows = filterSeconds(filterRows)
        filterRows = filterTextLen(filterRows)
        filterRows = filterSearch(filterRows)
        filterRows = filterSpaces(filterRows)
        setRows(filterRows)
        userStore.setDuration(calcDuration(filterRows))
    }

    const addSpaces = (index, filterRows, newFilterRows) => {
        // add spaces if needed
        // one before and one after if exists
        if (index > 0 && filterRows[index - 1]['space']) {
            newFilterRows.push(filterRows[index - 1])
        }

        newFilterRows.push(filterRows[index])

        if (index < filterRows.length - 1 && filterRows[index + 1]['space']) {
            newFilterRows.push(filterRows[index + 1])
        }
        return newFilterRows
    }

    const filterSentiment = (filterRows) => {
        const sentimentKeys = filterStore.getSentimentsTrue()
        if (sentimentKeys.length > 0) {
            let newFilterRows = []
            filterRows.forEach((_, index) => {
                let result = false
                for (const key of sentimentKeys) {
                    result = result || filterRows[index]['sentiment'] === key
                }
                if (result) {
                    newFilterRows = addSpaces(index, filterRows, newFilterRows)
                }

            })
            filterRows = newFilterRows
        }
        return filterRows
    }

    const filterSpeakers = (filterRows) => {
        const speakerKeys = filterStore.getSpeakersTrue()
        if (speakerKeys.length > 0) {
            let newFilterRows = []
            filterRows.forEach((_, index) => {
                let result = false
                for (const key of speakerKeys) {
                    result = result || filterRows[index]['speaker'] === key
                }
                if (result) {
                    newFilterRows = addSpaces(index, filterRows, newFilterRows)
                }
            })
            filterRows = newFilterRows
        }
        return filterRows
    }

    const filterSeconds = (filterRows) => {
        const sec = filterStore.getSeconds()
        let newFilterRows = []
        filterRows.forEach((_, index) => {
            if (filterRows[index]['sec'] >= sec[0] && filterRows[index]['sec'] <= sec[1]) {
                newFilterRows.push(filterRows[index])
            }
        })
        return (newFilterRows.length === 0 ? filterRows : newFilterRows)
    }
    const filterTextLen = (filterRows) => {
        const length = filterStore.getTextLen()
        let newFilterRows = []
        filterRows.forEach((_, index) => {
            if (filterRows[index]['length'] >= length[0] && filterRows[index]['length'] <= length[1]) {
                newFilterRows.push(filterRows[index])
            }
        })
        return (newFilterRows.length === 0 ? filterRows : newFilterRows)
    }
    const filterSearch = (filterRows) => {
        const spaces = filterStore.getSpacesOnOff()
        const [regex, regex1, regex2] = reGex(filterStore.getSearchEscaped())
        filterRows = filterRows.filter((obj) => {
            return regex.test(obj['sentence']) || regex1.test(obj['sentence']) || regex2.test(obj['sentence'] || (spaces && obj['space']))
        })
        return filterRows
    }

    const filterSpaces = (filterRows) => {
        if (!filterStore.getSpacesOnOff()) {
            filterRows = filterRows.filter((obj) => !obj['space'])
        }
        return filterRows
    }
    const onSentimentChange = (event) => {
        shouldStopVideo()
        filterStore.setSentiments(event.target.value)
        filterAll()
    }

    const onSpeakerChange = (event) => {
        filterStore.setResetSpeakers(event.target.value)
        filterAll()
    }

    const onSecChange = (values) => {
        filterStore.setSeconds(values)
        filterAll()
    }

    const onSetSpaces = () => {
        filterStore.setSpacesOnOff()
        filterAll()
    }

    const onTextLenChange = (values) => {
        filterStore.setTextLen(values)
        filterAll()
    }

    const onSearchChange = (event) => {
        filterStore.setSearch(event.target.value.toLowerCase())
        filterAll()
    }

    const onResetButtonFocus = () => {
        shouldStopVideo()
        onResetFilters()
    }

    const resetFilters = (data) => {
        filterStore.resetFilters(
            getDimension('speaker', data),
            getDimensionMinMax('length', data),
            getDimensionMinMax('sec', data),
        )

    }
    const onResetFilters = () => {
        shouldStopVideo()
        const x = rows
        let newRows = originalRows.map(item => ({...item}))
        resetFilters(newRows)
        setRows(newRows.filter(obj => {
            if (!obj['space']) return obj
        }))
        setOrder(1)
        filterStore.resetSelectedSentences()
        setReset(Math.random())
        indeterminateCheck(newRows)
        userStore.setDuration(calcDuration(newRows))
    }
    const getDimensionMinMax = (field, obj) => {
        const dimension = [...new Set(obj.map(obj => obj[field]))]
        return [Math.min(...dimension), Math.max(...dimension)];
    }

    const getDimension = (field, obj) => {
        return [...new Set(obj.map(obj => obj[field]))].sort().slice(1)
    }

    const setColor = (id, colorClass, op) => {
        const element = document.getElementById(id)
        if (element) {
            if (op === '-') {
                element.classList.remove(colorClass)
                return
            }
            element.classList.add(colorClass)
        }
    }


    const showSubtitles = () => {
        /**
         * Actually, the elapsed time is not exactly INTERVAL_MS maybe a bit more or less
         * special in the Firefox browser
         */

        const elapsed = (performance.now() - userStore.getStartTime()) / 1000
        userStore.setStartTime(performance.now())

        if (!filterStore.getSubtitlesOnOff()) {
            // keep updating the timeline, just in case a user changes their mind
            userStore.setSentenceStart(userStore.getSentenceStart() + elapsed)
            return
        }
        const timeLine = userStore.getSentenceStart()
        const previousWord = userStore.getPreviousWord()
        const item = userStore.getItem()
        const data = userStore.getVideoData()
        const wordIndex = data['utterance'].findIndex((item) => timeLine >= item[0] && timeLine <= item[1])

        userStore.setSentenceStart(timeLine + elapsed)
        setColor(`s-${item}-${previousWord}`, subtitleColorHighlight, '-')
        setColor(`s-${item}-${wordIndex}`, subtitleColorHighlight, '+')
        userStore.setPreviousWord(wordIndex)
    }

    const onSetResetSubtitles = () => {
        filterStore.setSubtitlesOnOff()
        if (filterStore.getSubtitlesOnOff()) {
            if (userStore.getVideoData() !== null) clearSubtitles()
        }
    }


    const clearSubtitles = () => {
        // it ends before the word has a chance to be delighted,
        // so this clears the highlight word when the video stops
        // no matter when it stops
        const item = userStore.getItem()
        const data = userStore.getVideoData()

        for (let i = 0; i < data['utterance'].length; i++) {
            setColor(`s-${item}-${i}`, subtitleColorHighlight, '-')
        }
    }
    const shouldStopVideo = () => (userStore.getItem() !== null ? videoStop() : null)

    const handleRangeChanged = (range) => {
        // stop video if is not visible
        const playing = userStore.getItem()
        if (playing === null) return
        if (!(playing > range.startIndex && playing < range.endIndex)) {
            videoStop()
        }
    }

    const playFired = () => {
        const durationMs = userStore.getVideoDurationMs()
        userStore.setTimeOutId(setTimeout(videoStop, durationMs))
        userStore.setStartTime(performance.now())
        userStore.setIntervalId(setInterval(() => showSubtitles(), INTERVAL))
    }
    const videoStop = () => {
        const video = userStore.getVideo()

        if (video) {
            const ext = userStore.getStartIn()
            clearInterval(userStore.getIntervalId())
            clearTimeout(userStore.getTimeOutId())
            video.pause();
            // reload the poster image

            video.poster = `${thumbnailFile}-${(ext === 0 ? '0.0' : ext)}.jpg`
            video.load();
            clearSubtitles()
            video.removeEventListener('play', playFired)
            userStore.resetVideo()
        }
    }


    const startVideo = () => {
        const video = userStore.getVideo()

        if (video.paused || video.ended) {
            video.addEventListener("play", playFired)
            video.currentTime = userStore.getStartIn()
            video.play();
        } else {
            videoStop()
        }
    }
    const onVideoClick = (event, data, item) => {
        const previousVideo = userStore.getVideo()
        if (previousVideo !== event.target) {
            videoStop()
        }
        const video = event.target;
        if (video.paused || video.ended) {
            userStore.setVideo(data, video, item)
            startVideo()
        } else {
            videoStop()
        }
    };

    useEffect(() => {
        if (transcriptId === null) return

        setLoading(true)
        let buffer = []
        let index = 0
        const request = new XMLHttpRequest();

        // false makes the request synchronous, get first page
        request.open("GET", `${getSentencesUrl}${transcriptId}/${index}/`, false);
        request.send(null);

        if (request.readyState === 4 && request.status === 200) {
            const result = JSON.parse(request.responseText)
            setVideoFile(domainNoSlash + result[PATH] + 'videos/' + result[FILENAME] + '.mp4')
            setThumbnailFile(`${domainNoSlash}${result[PATH]}thumbnails/${result[TITLE_SLUG]}/${result[FILENAME]}`)
            setVideoDuration(result[VIDEO_DURATION])
            userStore.setTotalDuration(durationFormat(result[VIDEO_DURATION] * 1000))
            buffer = [...result[DATA]]
            let next = result[INDEX]

            while (next) {
                index += 1
                const request = new XMLHttpRequest();
                request.open("GET", `${getSentencesUrl}${transcriptId}/${index}/`, false);
                request.send(null);
                if (request.readyState === 4 && request.status === 200) {
                    const obj = JSON.parse(request.responseText)
                    buffer = buffer.concat(obj[DATA])
                    next = obj[INDEX]
                } else {
                    // console.log('error')
                    break;
                }
            }
        }

        buffer = buffer.map((obj, index) => {
            obj['order'] = maxLines
            obj['sentiment'] = sentClasses[obj['sentiment']]
            return obj
        })
        userStore.setDuration(calcDuration(buffer))
        setOriginalRows(buffer.map(item => ({...item})))
        setRows(buffer.filter(obj => {
            if (!obj['space']) return obj
        }));
        setLoading(false);
        resetFilters(buffer)
        indeterminateCheck(buffer)
    }, [])

    const onSortClick = (index, direction) => {
        shouldStopVideo()
        // for the time being sort only by one column
        const newHeader = header.map((item, i) => {
            if (i === index) {
                item.isUp = !item.isUp
                return item
            }
            item.isUp = false
            return item
        })
        setHeader(newHeader)

        // special case for order
        if (header[index].restore) {
            const field = 'order'
            const sorted = rows.sort((a, b) => {
                if (a[field] > b[field]) return 1
                if (a[field] < b[field]) return -1
                return 0
            })
            setRows(sorted)
            return
        }

        const sortField = header[index].field.toLowerCase()

        const sorted = rows.sort((a, b) => {
            if (direction === 'up') {
                return a[sortField] > b[sortField] ? 1 : -1;
            } else {
                return a[sortField] < b[sortField] ? 1 : -1;
            }
        })
        setRows(sorted)
    }

    const indeterminateCheck = (newRows) => {
        const howManySelected = filterStore.howManySelectedSentences()
        if (howManySelected === 0) {
            setChecked([false, false])
        } else if (howManySelected === newRows.length) {
            setChecked([true, false])
        } else {
            setChecked([false, true])
        }
    }

    const onCheckboxChange = (event, item, id) => {
        let newState = maxLines
        if (event.target.checked) {
            filterStore.setSelectedSentences(id)
            setOrder(order + 1)
            newState = order
        } else {
            filterStore.removeSelectedSentence(id)
        }
        let newRows = rows.slice()
        newRows[item]['order'] = newState
        setRows(newRows)
        indeterminateCheck(newRows)
        userStore.setDuration(calcDuration(newRows))
    }

    const itemContent = (item, data) => {
        const [regex, regex1, regex2] = reGex(filterStore.getSearchEscaped())
        return (
            header.map((obj, index) => {
                    if (obj.visible) {
                        if (obj.type === 'checkbox') {

                            return (
                                <td key={index} className={`${obj.field} checkbox`}>

                                    <Stack direction="row" spacing={0} alignItems={"center"}>
                                        <Typography style={{marginLeft: 2, fontSize: 11}}>{item + 1}  </Typography>
                                        <Checkbox
                                            sx={{'& .MuiSvgIcon-root': {fontSize: 15,}}}
                                            value={data['id']}
                                            checked={data['order'] !== maxLines}
                                            onChange={(event) => onCheckboxChange(event, item, data['id'])}
                                        />

                                    </Stack>
                                </td>
                            )
                        }
                        if (obj.type === 'video') {
                            return (
                                <td key={index} className={`${obj.field} video`}>
                                    <video id={`v-${item}`}
                                           playsInline
                                           className="videos" width="110"
                                           src={videoFile} type="video/mp4"
                                           preload="none"
                                           poster={`${thumbnailFile}-${(data.start_in === 0 ? '0.0' : data.start_in)}.jpg`}
                                           onClick={(event) => {
                                               onVideoClick(event, data, item)
                                           }}
                                    />
                                </td>
                            )
                        }
                        if (obj.type === 'boolean') {
                            return (
                                <td key={index} className={`${obj.field} boolean`}>
                                    {(data[obj.field] ? <CheckIcon color="success"/> : null)}
                                </td>
                            )
                        }
                        if (obj.field === 'sentence') {
                            const search = filterStore.getSearch()

                            let html = data[obj.field].split(' ').map((word_punctuation, index) => {
                                    let word = word_punctuation
                                    if (search.length > 0) {
                                        if (regex.test(word) || regex1.test(word) || regex2.test(word)) {
                                            const wordLower = word.toLowerCase()
                                            const parts = wordLower.split(search)
                                            const pos = wordLower.indexOf(search)
                                            switch (parts.length) {
                                                case 2:
                                                    if (parts[0].length === 0) {
                                                        return `<span id="s-${item}-${index}"  class="highlight">${word.slice(pos, search.length)}</span><span id="s-${item}-${index}">${parts[1]}</span>`
                                                    }
                                                    return `<span id="s-${item}-${index}">${word.slice(0, parts[0].length)}</span><span id="s-${item}-${index}"  class="highlight">${search}</span>`
                                                case 3:
                                                    return `<span id="s-${item}-${index}">${parts[0]}</span><span id="s-${item}-${index}"  class="highlight">${search}</span><span id="s-${item}-${index}">${parts[1]}</span><span id="s-${item}-${index}">${parts[2]}</span>`
                                            }
                                        }
                                    }
                                    return `<span id="s-${item}-${index}">${word}</span>`
                                }
                            )
                            html = html.join(' ')

                            return (
                                <td key={index} className={`${obj.field} ${obj.type}`}>
                                    {parse(html)}
                                </td>
                            )
                        }
                        if (obj.field === 'order') {
                            return (
                                <td key={index} className={`${obj.field} ${obj.type}`}>
                                    {data[obj.field] === maxLines ? '' : data[obj.field]}
                                </td>
                            )
                        }
                        if (obj.field === 'sentiment') {
                            return (
                                <td key={index} className={`${data[obj.field]} ${obj.type}`}>
                                    {data[obj.field]}
                                </td>
                            )
                        }
                        if (obj.field === 'speaker') {
                            return (
                                <td key={index} className={`${data[obj.field]} ${obj.type}`} style={{maxWidth: '40'}}>
                                    {data[obj.field]}
                                </td>
                            )
                        }
                        if (obj.field === 'duration') {
                            return (
                                <td key={index} className={`${obj.field} ${obj.type}`} style={{maxWidth: '40'}}>
                                    <p>{data[obj.field][0]}</p>
                                    <p>{data[obj.field][1]}</p>
                                </td>
                            )

                        } else {
                            return (
                                <td key={index} className={`${obj.field} ${obj.type}`}>
                                    {data[obj.field]}
                                </td>
                            )
                        }
                    }
                    return null
                }
            )
        )
    }

    const onCheckAll = (event) => {
        const checked = event.target.checked
        const newRows = rows.map((obj, index) => {
            obj['order'] = checked ? index + 1 : maxLines
            return obj
        })
        setRows(newRows)
        if (checked) {
            filterStore.setAllSelectedSentences(newRows)
        } else {
            filterStore.resetSelectedSentences()
        }
        indeterminateCheck(newRows)
        userStore.setDuration(calcDuration(newRows))
    }

    // don't render until these conditions are ok
    if (filterStore.getTextLenIsEmpty() ||
        filterStore.getSecondsIsEmpty() ||
        filterStore.getSpeakersIsEmpty() ||
        filterStore.getSentimentsIsEmpty()

    ) return <Loading/>;

    if (loading) return <Loading/>

    // if (error) return message.setMessage(`Error:${error}`, 'error')

    const columns = 12
    return (
        <Box style={{width: '100%', margin: '0 150 0 0'}}>
            <Grid container xs={columns}>
                <AppBar position="fixed" style={{top: 64, display: 'block'}}>
                    <Grid container xs={columns}
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="left"
                          spacing={4}
                    >
                        <Grid item xs={0.6}>
                            <Result header={"#"} number={rows.length} underline={false}/>
                        </Grid>

                        <Grid>
                            <CheckboxPanel objs={filterStore.getSentiments()}
                                           label="Sentiment"
                                           orientation='row'
                                           colors={['positive', 'negative', 'neutral']}
                                           maxLength={3}
                                           onChange={onSentimentChange}

                            />
                        </Grid>
                        <Grid>
                            <CheckboxPanel objs={filterStore.getSpeakers()}
                                           label="Speaker"
                                           orientation="row"
                                           maxLength={0}
                                           onChange={onSpeakerChange}
                            />
                        </Grid>
                        <Grid>
                            <SliderNumeric textLen={filterStore.getTextLen()}
                                           label={'Len'}
                                           color="success"
                                           callback={onTextLenChange}
                                           reset={reset}
                            />
                        </Grid>
                        <Grid>
                            <SliderNumeric textLen={filterStore.getSeconds()}
                                           label={'Sec'}
                                           color="warning"
                                           callback={onSecChange}
                                           reset={reset}
                            />
                        </Grid>

                        <Grid item xs={0.2}>
                            <Tooltip title="Reset filters to default">
                                <IconButton
                                    onClick={onResetFilters}
                                    onFocus={onResetButtonFocus}>
                                    <RestartAltIcon color="action" sx={iconStyle}/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={0.2}>
                            <Tooltip title="Subtitle on/off">
                                <IconButton
                                    onClick={onSetResetSubtitles}
                                >
                                    {(filterStore.getSubtitlesOnOff() ?
                                        <SubtitlesIcon color="action" sx={iconStyle}/> :
                                        <SubtitlesOffIcon color="action" sx={iconStyle}/>)}
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={0.2}>
                            <Tooltip title="Spaces on/off">
                                <IconButton
                                    onClick={onSetSpaces}
                                >
                                    {(filterStore.getSpacesOnOff() ?
                                        <CommentIcon color="action" sx={iconStyle}/> :
                                        <CommentsDisabledIcon color="action" sx={iconStyle}/>)}
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid>
                            <SentencesMenu/>
                        </Grid>

                        <Grid>
                            <SearchField
                                onChange={onSearchChange}
                                store={filterStore}
                            />
                        </Grid>
                    </Grid>
                </AppBar>
            </Grid>
            <Grid container xs={12}>
                <Grid item xs={12} style={{width: '100%'}}>
                    <TableVirtuoso
                        data={rows}
                        style={{overflow: 'auto', marginTop: 120, height: 'calc(100vh - 120px)'}}
                        fixedHeaderContent={() => <Header header={header}
                                                          onClick={onSortClick}
                                                          Menu={<SentencesMenu/>}
                                                          checked={checked}
                                                          handleCheck={onCheckAll}
                        />}
                        itemContent={itemContent}
                        rangeChanged={handleRangeChanged}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default observer(CreateVideo)