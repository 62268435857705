// Django server
export let domain = ''
export let domainNoSlash = ''
export let hostApi = ''

// Django server endpoints
if (process.env.NODE_ENV === 'development') {
    domain = 'http://dev.emo.com/';
    domainNoSlash = 'http://dev.emo.com';

    hostApi = 'http://localhost:9000/api/v1/';

} else {
    domain = 'https://www.emotranscripts.com/'
    domainNoSlash = 'https://www.emotranscripts.com'
    hostApi = 'https://www.emotranscripts.com/api/v1/';
}
export const authUrl = {
    'get_email': `${hostApi}get-email/`,
    'get_pass': `${hostApi}get-code/`,
    'signUp': `${hostApi}sign-up/`,
}
export const getUserUrl = `${hostApi}get-user/`;

export const logoutUrl = `${hostApi}logout/`;
export const csrfUrl = `${hostApi}csrf/`;
export const getUserVideosUrl = `${hostApi}user-downloaded-videos/`;
export const downloadUrl = `${hostApi}download-videos/`;
export const uploadUrl = `${hostApi}upload-video/`;
export const getSentencesUrl = `${hostApi}user-create/`;
export const makeVideoUrl = `${hostApi}make-video/`;
export const getMyCreationsUrl = `${hostApi}my-creations/`;
export const host = 'http://localhost/';

export const getPagesUrl = `${hostApi}get-pages/`;